import { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import Xarrow from 'react-xarrows';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import family from '../../../helpers/family';
import Ancestor from './Ancestor';
import { T, useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: '1rem',
    alignContent: 'center',
  },
  question: {
    fontWeight: 600,
  },
  tooltip: {
    fontSize: '0.9rem',
    marginTop: '1rem',
    fontStyle: 'italic',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  ancestors: {
    margin: '1rem 0',
  },
  ancestor: {
    marginBottom: '1rem',
    alignContent: 'center',
  },
  description: {
    fontSize: '0.8rem',
    padding: '0 !important',
  },
}));

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

interface Ancestors {
  ancestor: string[];
  ancestors: string[];
}

interface IAncestor {
  id: number;
  name: string;
  checked: boolean;
  ancestor: boolean;
}

const PolishAncestor = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslate();

  const translate = [
    t('i'),
    t('mother'),
    t('father'),
    t('maternal_grandmother'),
    t('maternal_grandfather'),
    t('paternal_grandmother'),
    t('paternal_grandfather'),
    t('maternal_grandmothers_mother'),
    t('maternal_grandmothers_father'),
    t('maternal_grandfathers_mother'),
    t('maternal_grandfathers_father'),
    t('paternal_grandmothers_mother'),
    t('paternal_grandmothers_father'),
    t('paternal_grandfathers_mother'),
    t('paternal_grandfathers_father'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
    t('older'),
  ];

  const formikContext = useFormikContext<Ancestors>();

  const [ancestorArray, setAncestorArray] = useState<IAncestor[]>([
    {
      id: 0,
      name: 'I',
      checked: true,
      ancestor: true,
    },
    {
      id: 1,
      name: 'Mother',
      checked: false,
      ancestor: false,
    },
    {
      id: 2,
      name: 'Father',
      checked: false,
      ancestor: false,
    },
    {
      id: 3,
      name: 'Maternal Grandmother',
      checked: false,
      ancestor: false,
    },
    {
      id: 4,
      name: 'Maternal Grandfather',
      checked: false,
      ancestor: false,
    },
    {
      id: 5,
      name: 'Paternal Grandmother',
      checked: false,
      ancestor: false,
    },
    {
      id: 6,
      name: 'Paternal Grandfather',
      checked: false,
      ancestor: false,
    },
    {
      id: 7,
      name: 'Maternal Grandmothers Mother',
      checked: false,
      ancestor: false,
    },
    {
      id: 8,
      name: 'Maternal Grandmothers Father',
      checked: false,
      ancestor: false,
    },
    {
      id: 9,
      name: 'Maternal Grandfathers Mother',
      checked: false,
      ancestor: false,
    },
    {
      id: 10,
      name: 'Maternal Grandfathers Father',
      checked: false,
      ancestor: false,
    },
    {
      id: 11,
      name: 'Paternal Grandmothers Mother',
      checked: false,
      ancestor: false,
    },
    {
      id: 12,
      name: 'Paternal Grandmothers Father',
      checked: false,
      ancestor: false,
    },
    {
      id: 13,
      name: 'Paternal Grandfathers Mother',
      checked: false,
      ancestor: false,
    },
    {
      id: 14,
      name: 'Paternal Grandfathers Father',
      checked: false,
      ancestor: false,
    },
    {
      id: 15,
      name: 'Older1',
      checked: false,
      ancestor: false,
    },
    {
      id: 16,
      name: 'Older2',
      checked: false,
      ancestor: false,
    },
    {
      id: 17,
      name: 'Older3',
      checked: false,
      ancestor: false,
    },
    {
      id: 18,
      name: 'Older4',
      checked: false,
      ancestor: false,
    },
    {
      id: 19,
      name: 'Older5',
      checked: false,
      ancestor: false,
    },
    {
      id: 20,
      name: 'Older6',
      checked: false,
      ancestor: false,
    },
    {
      id: 21,
      name: 'Older7',
      checked: false,
      ancestor: false,
    },
    {
      id: 22,
      name: 'Older8',
      checked: false,
      ancestor: false,
    },
    {
      id: 23,
      name: 'Older9',
      checked: false,
      ancestor: false,
    },
    {
      id: 24,
      name: 'Older10',
      checked: false,
      ancestor: false,
    },
    {
      id: 25,
      name: 'Older11',
      checked: false,
      ancestor: false,
    },
    {
      id: 26,
      name: 'Older12',
      checked: false,
      ancestor: false,
    },
    {
      id: 27,
      name: 'Older13',
      checked: false,
      ancestor: false,
    },
    {
      id: 28,
      name: 'Older14',
      checked: false,
      ancestor: false,
    },
    {
      id: 29,
      name: 'Older15',
      checked: false,
      ancestor: false,
    },
    {
      id: 30,
      name: 'Older16',
      checked: false,
      ancestor: false,
    },
  ]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const ancestor = formikContext.values.ancestor;
    for (const anc of ancestor) {
      for (let i = 0; i < ancestorArray.length; i++) {
        if (anc === ancestorArray[i].name) {
          setAncestorArray((ancestorArray) => {
            const newAncestorArray = [...ancestorArray];
            newAncestorArray[i] = {
              ...newAncestorArray[i],
              checked: true,
            };
            return newAncestorArray;
          });
        }
      }
    }

    const ancestors = formikContext.values.ancestors;
    for (const anc of ancestors) {
      for (let i = 0; i < ancestorArray.length; i++) {
        if (anc === ancestorArray[i].name) {
          setAncestorArray((ancestorArray) => {
            const newAncestorArray = [...ancestorArray];
            newAncestorArray[i] = {
              ...newAncestorArray[i],
              ancestor: true,
            };
            return newAncestorArray;
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    setAncestorArray((ancestorArray) => {
      const newAncestorArray = [...ancestorArray];
      newAncestorArray[0] = {
        ...newAncestorArray[0],
        name: 'I',
      };
      return newAncestorArray;
    });
  }, [ancestorArray[0]]);

  const handleTree = (id: number, name: string, checked: boolean) => {
    setAncestorArray((ancestorArray) => {
      const newAncestorArray = [...ancestorArray];
      newAncestorArray[id] = {
        ...newAncestorArray[id],
        checked: !ancestorArray[id].checked,
      };
      return newAncestorArray;
    });

    let ancestor = formikContext.values.ancestor;
    const index = ancestor.findIndex((element) => element === name);
    if (index > -1) {
      ancestor = ancestor.filter((value) => value !== name);
    } else {
      ancestor.push(name);
    }
    formikContext.setFieldValue('ancestor', ancestor);

    const ancestors: string[] = [];
    for (let anc of ancestor) {
      const newAncestors: string[] = [];
      family.printAncestors(family, anc, newAncestors);
      newAncestors.pop();
      newAncestors.shift();
      for (let anc of newAncestors) {
        if (!ancestors.includes(anc) && anc !== 'Me') {
          ancestors.push(anc);
        }
      }
    }
    formikContext.setFieldValue('ancestors', ancestors);

    for (let i = 0; i < ancestorArray.length; i++) {
      setAncestorArray((ancestorArray) => {
        const newAncestorArray = [...ancestorArray];
        newAncestorArray[i] = {
          ...newAncestorArray[i],
          ancestor: false,
        };
        return newAncestorArray;
      });
    }

    for (const anc of ancestors) {
      for (let i = 0; i < ancestorArray.length; i++) {
        if (anc === ancestorArray[i].name) {
          setAncestorArray((ancestorArray) => {
            const newAncestorArray = [...ancestorArray];
            newAncestorArray[i] = {
              ...newAncestorArray[i],
              ancestor: true,
            };
            return newAncestorArray;
          });
        }
      }
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} justifyContent="center">
        <Box marginTop="2rem" width="100%">
          <FormControl
            component="fieldset"
            style={{ width: windowDimensions.width >= 900 ? '100%' : 'auto' }}
          >
            <FormLabel component="legend" className={classes.question}>
              <T keyName="please_specify_your_polish_lineage">
                Please specify your Polish lineage
              </T>
            </FormLabel>
            <Box component="span" className={classes.tooltip}>
              <T keyName="please_select_the_last_adult_ancestor_that_lived_in_poland">
                Please select the last adult ancestor that lived in Poland
              </T>
            </Box>
            {windowDimensions.width < 900 && (
              <>
                <Xarrow
                  start="I"
                  end="Mother"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="I"
                  end="Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Mother"
                  end="Maternal Grandmother"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Mother"
                  end="Maternal Grandfather"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmother"
                  end="Maternal Grandmothers Mother"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmother"
                  end="Maternal Grandmothers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfather"
                  end="Maternal Grandfathers Mother"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfather"
                  end="Maternal Grandfathers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Mother"
                  end="Older1"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Mother"
                  end="Older2"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Father"
                  end="Older3"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Father"
                  end="Older4"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Mother"
                  end="Older5"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Mother"
                  end="Older6"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Father"
                  end="Older7"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Father"
                  end="Older8"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Mother"
                  end="Older9"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Mother"
                  end="Older10"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Father"
                  end="Older11"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Father"
                  end="Older12"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Mother"
                  end="Older13"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Mother"
                  end="Older14"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Father"
                  end="Older15"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Father"
                  end="Older16"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmother"
                  end="Paternal Grandmothers Mother"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmother"
                  end="Paternal Grandmothers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfather"
                  end="Paternal Grandfathers Mother"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfather"
                  end="Paternal Grandfathers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Father"
                  end="Paternal Grandmother"
                  startAnchor="top"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Father"
                  end="Paternal Grandfather"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
              </>
            )}
            {windowDimensions.width >= 900 && (
              <>
                <Xarrow
                  start="I"
                  end="Mother"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="I"
                  end="Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Mother"
                  end="Maternal Grandmother"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Mother"
                  end="Maternal Grandfather"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmother"
                  end="Maternal Grandmothers Mother"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmother"
                  end="Maternal Grandmothers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfather"
                  end="Maternal Grandfathers Mother"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfather"
                  end="Maternal Grandfathers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Mother"
                  end="Older1"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Mother"
                  end="Older2"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Father"
                  end="Older3"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandmothers Father"
                  end="Older4"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Mother"
                  end="Older5"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Mother"
                  end="Older6"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Father"
                  end="Older7"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Maternal Grandfathers Father"
                  end="Older8"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Mother"
                  end="Older9"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Mother"
                  end="Older10"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Father"
                  end="Older11"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmothers Father"
                  end="Older12"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Mother"
                  end="Older13"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Mother"
                  end="Older14"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Father"
                  end="Older15"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfathers Father"
                  end="Older16"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmother"
                  end="Paternal Grandmothers Mother"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandmother"
                  end="Paternal Grandmothers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfather"
                  end="Paternal Grandfathers Mother"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Paternal Grandfather"
                  end="Paternal Grandfathers Father"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Father"
                  end="Paternal Grandmother"
                  startAnchor="bottom"
                  endAnchor="right"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
                <Xarrow
                  start="Father"
                  end="Paternal Grandfather"
                  startAnchor="bottom"
                  endAnchor="left"
                  path="grid"
                  headSize={0}
                  strokeWidth={3}
                  color={theme.palette.primary.main}
                />
              </>
            )}
            {windowDimensions.width < 900 && (
              <Grid
                container
                spacing={1}
                wrap="nowrap"
                className={classes.ancestors}
              >
                <Grid
                  container
                  item
                  xs
                  direction="column"
                  justifyContent="space-around"
                  spacing={2}
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[0].id}
                      name={ancestorArray[0].name}
                      translate={translate[0]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[0].checked}
                      ancestor={ancestorArray[0].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  direction="column"
                  justifyContent="space-around"
                  spacing={2}
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[1].id}
                      name={ancestorArray[1].name}
                      translate={translate[1]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[1].checked}
                      ancestor={ancestorArray[1].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[2].id}
                      name={ancestorArray[2].name}
                      translate={translate[2]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[2].checked}
                      ancestor={ancestorArray[2].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  direction="column"
                  justifyContent="space-around"
                  spacing={2}
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[3].id}
                      name={ancestorArray[3].name}
                      translate={translate[3]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[3].checked}
                      ancestor={ancestorArray[3].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[4].id}
                      name={ancestorArray[4].name}
                      translate={translate[4]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[4].checked}
                      ancestor={ancestorArray[4].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[5].id}
                      name={ancestorArray[5].name}
                      translate={translate[5]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[5].checked}
                      ancestor={ancestorArray[5].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[6].id}
                      name={ancestorArray[6].name}
                      translate={translate[6]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[6].checked}
                      ancestor={ancestorArray[6].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  direction="column"
                  justifyContent="space-around"
                  spacing={2}
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[7].id}
                      name={ancestorArray[7].name}
                      translate={translate[7]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[7].checked}
                      ancestor={ancestorArray[7].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[8].id}
                      name={ancestorArray[8].name}
                      translate={translate[8]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[8].checked}
                      ancestor={ancestorArray[8].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[9].id}
                      name={ancestorArray[9].name}
                      translate={translate[9]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[9].checked}
                      ancestor={ancestorArray[9].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[10].id}
                      name={ancestorArray[10].name}
                      translate={translate[10]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[10].checked}
                      ancestor={ancestorArray[10].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[11].id}
                      name={ancestorArray[11].name}
                      translate={translate[11]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[11].checked}
                      ancestor={ancestorArray[11].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[12].id}
                      name={ancestorArray[12].name}
                      translate={translate[12]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[12].checked}
                      ancestor={ancestorArray[12].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[13].id}
                      name={ancestorArray[13].name}
                      translate={translate[13]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[13].checked}
                      ancestor={ancestorArray[13].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[14].id}
                      name={ancestorArray[14].name}
                      translate={translate[14]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[14].checked}
                      ancestor={ancestorArray[14].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  direction="column"
                  justifyContent="space-around"
                  spacing={2}
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[15].id}
                      name={ancestorArray[15].name}
                      translate={translate[15]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[15].checked}
                      ancestor={ancestorArray[15].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[16].id}
                      name={ancestorArray[16].name}
                      translate={translate[16]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[16].checked}
                      ancestor={ancestorArray[16].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[17].id}
                      name={ancestorArray[17].name}
                      translate={translate[17]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[17].checked}
                      ancestor={ancestorArray[17].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[18].id}
                      name={ancestorArray[18].name}
                      translate={translate[18]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[18].checked}
                      ancestor={ancestorArray[18].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[19].id}
                      name={ancestorArray[19].name}
                      translate={translate[19]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[19].checked}
                      ancestor={ancestorArray[19].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[20].id}
                      name={ancestorArray[20].name}
                      translate={translate[20]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[20].checked}
                      ancestor={ancestorArray[20].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[21].id}
                      name={ancestorArray[21].name}
                      translate={translate[21]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[21].checked}
                      ancestor={ancestorArray[21].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[22].id}
                      name={ancestorArray[22].name}
                      translate={translate[22]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[22].checked}
                      ancestor={ancestorArray[22].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[23].id}
                      name={ancestorArray[23].name}
                      translate={translate[23]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[23].checked}
                      ancestor={ancestorArray[23].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[24].id}
                      name={ancestorArray[24].name}
                      translate={translate[24]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[24].checked}
                      ancestor={ancestorArray[24].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[25].id}
                      name={ancestorArray[25].name}
                      translate={translate[25]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[25].checked}
                      ancestor={ancestorArray[25].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[26].id}
                      name={ancestorArray[26].name}
                      translate={translate[26]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[26].checked}
                      ancestor={ancestorArray[26].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[27].id}
                      name={ancestorArray[27].name}
                      translate={translate[27]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[27].checked}
                      ancestor={ancestorArray[27].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[28].id}
                      name={ancestorArray[28].name}
                      translate={translate[28]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[28].checked}
                      ancestor={ancestorArray[28].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[29].id}
                      name={ancestorArray[29].name}
                      translate={translate[29]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[29].checked}
                      ancestor={ancestorArray[29].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[30].id}
                      name={ancestorArray[30].name}
                      translate={translate[30]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[30].checked}
                      ancestor={ancestorArray[30].ancestor}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {windowDimensions.width >= 900 && (
              <Grid container spacing={1} className={classes.ancestors}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-around"
                  spacing={2}
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[0].id}
                      name={ancestorArray[0].name}
                      translate={translate[0]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[0].checked}
                      ancestor={ancestorArray[0].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-around"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[1].id}
                      name={ancestorArray[1].name}
                      translate={translate[1]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[1].checked}
                      ancestor={ancestorArray[1].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[2].id}
                      name={ancestorArray[2].name}
                      translate={translate[2]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[2].checked}
                      ancestor={ancestorArray[2].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-around"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[3].id}
                      name={ancestorArray[3].name}
                      translate={translate[3]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[3].checked}
                      ancestor={ancestorArray[3].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[4].id}
                      name={ancestorArray[4].name}
                      translate={translate[4]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[4].checked}
                      ancestor={ancestorArray[4].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[5].id}
                      name={ancestorArray[5].name}
                      translate={translate[5]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[5].checked}
                      ancestor={ancestorArray[5].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[6].id}
                      name={ancestorArray[6].name}
                      translate={translate[6]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[6].checked}
                      ancestor={ancestorArray[6].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-around"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[7].id}
                      name={ancestorArray[7].name}
                      translate={translate[7]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[7].checked}
                      ancestor={ancestorArray[7].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[8].id}
                      name={ancestorArray[8].name}
                      translate={translate[8]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[8].checked}
                      ancestor={ancestorArray[8].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[9].id}
                      name={ancestorArray[9].name}
                      translate={translate[9]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[9].checked}
                      ancestor={ancestorArray[9].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[10].id}
                      name={ancestorArray[10].name}
                      translate={translate[10]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[10].checked}
                      ancestor={ancestorArray[10].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[11].id}
                      name={ancestorArray[11].name}
                      translate={translate[11]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[11].checked}
                      ancestor={ancestorArray[11].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[12].id}
                      name={ancestorArray[12].name}
                      translate={translate[12]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[12].checked}
                      ancestor={ancestorArray[12].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[13].id}
                      name={ancestorArray[13].name}
                      translate={translate[13]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[13].checked}
                      ancestor={ancestorArray[13].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[14].id}
                      name={ancestorArray[14].name}
                      translate={translate[14]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[14].checked}
                      ancestor={ancestorArray[14].ancestor}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-around"
                  spacing={2}
                >
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[15].id}
                      name={ancestorArray[15].name}
                      translate={translate[15]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[15].checked}
                      ancestor={ancestorArray[15].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[16].id}
                      name={ancestorArray[16].name}
                      translate={translate[16]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[16].checked}
                      ancestor={ancestorArray[16].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[17].id}
                      name={ancestorArray[17].name}
                      translate={translate[17]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[17].checked}
                      ancestor={ancestorArray[17].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[18].id}
                      name={ancestorArray[18].name}
                      translate={translate[18]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[18].checked}
                      ancestor={ancestorArray[18].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[19].id}
                      name={ancestorArray[19].name}
                      translate={translate[19]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[19].checked}
                      ancestor={ancestorArray[19].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[20].id}
                      name={ancestorArray[20].name}
                      translate={translate[20]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[20].checked}
                      ancestor={ancestorArray[20].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[21].id}
                      name={ancestorArray[21].name}
                      translate={translate[21]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[21].checked}
                      ancestor={ancestorArray[21].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[22].id}
                      name={ancestorArray[22].name}
                      translate={translate[22]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[22].checked}
                      ancestor={ancestorArray[22].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[23].id}
                      name={ancestorArray[23].name}
                      translate={translate[23]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[23].checked}
                      ancestor={ancestorArray[23].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[24].id}
                      name={ancestorArray[24].name}
                      translate={translate[24]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[24].checked}
                      ancestor={ancestorArray[24].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[25].id}
                      name={ancestorArray[25].name}
                      translate={translate[25]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[25].checked}
                      ancestor={ancestorArray[25].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[26].id}
                      name={ancestorArray[26].name}
                      translate={translate[26]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[26].checked}
                      ancestor={ancestorArray[26].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[27].id}
                      name={ancestorArray[27].name}
                      translate={translate[27]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[27].checked}
                      ancestor={ancestorArray[27].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[28].id}
                      name={ancestorArray[28].name}
                      translate={translate[28]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[28].checked}
                      ancestor={ancestorArray[28].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[29].id}
                      name={ancestorArray[29].name}
                      translate={translate[29]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[29].checked}
                      ancestor={ancestorArray[29].ancestor}
                    />
                  </Grid>
                  <Grid item>
                    <Ancestor
                      id={ancestorArray[30].id}
                      name={ancestorArray[30].name}
                      translate={translate[30]}
                      setTreeValue={handleTree}
                      checked={ancestorArray[30].checked}
                      ancestor={ancestorArray[30].ancestor}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!!formikContext.touched.ancestor &&
              !!formikContext.errors.ancestor && (
                <p className={classes.errorMessage}>
                  {formikContext.errors.ancestor}
                </p>
              )}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PolishAncestor;
