import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import SurveyForm from './questions/SurveyForm';
import Grid from '@material-ui/core/Grid';

import { T, useTolgee, useTranslate } from '@tolgee/react';

const LangSelector = () => {
  const tolgee = useTolgee(['language']);
  const { t } = useTranslate();

  return (
    <select
      onChange={(e) => tolgee.changeLanguage(e.target.value)}
      value={tolgee.getLanguage()}
    >
      <option value="en">🇬🇧 {t('english')}</option>
      <option value="es">🇪🇸 {t('spanish')}</option>
    </select>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '2rem',
  },
  logo: {
    width: '100%',
    height: 'auto',
    marginBottom: '2rem',
    maxWidth: '400px',
  },
}));

const Survey = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container justifyContent="flex-end">
        <LangSelector />
      </Grid>
      <Grid container justifyContent="center">
        <img
          src="/rotsy_Obszar roboczy 1-02.png"
          alt="Your Roots in Poland logo"
          className={classes.logo}
        />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Typography
            variant="h3"
            align="center"
            color="primary"
            gutterBottom
            className={classes.header}
          >
            <T keyName="polish_citizenship_quiz">POLISH CITIZENSHIP QUIZ</T>
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="body1" align="justify" gutterBottom>
            <T keyName="desc">
              Please answer the following questions to the best of your ability.
              You may not know the answer to many of these questions. We have a
              whole team of researchers in Poland who can help. Do the best you
              can!
            </T>
          </Typography>
        </Grid>
      </Grid>
      <SurveyForm />
    </>
  );
};

export default Survey;
