import FamilyMember from './FamilyMember';

const family = new FamilyMember('I', false, true);
family.mother = new FamilyMember('Mother', false, false);
family.father = new FamilyMember('Father', true, false);
family.mother.mother = new FamilyMember('Maternal Grandmother', false, false);
family.mother.father = new FamilyMember('Maternal Grandfather', true, false);
family.father.mother = new FamilyMember('Paternal Grandmother', false, false);
family.father.father = new FamilyMember('Paternal Grandfather', true, false);
family.mother.mother.mother = new FamilyMember(
  'Maternal Grandmothers Mother',
  false,
  false
);
family.mother.mother.mother.mother = new FamilyMember('Older1', false, false);
family.mother.mother.mother.father = new FamilyMember('Older2', false, false);
family.mother.mother.father = new FamilyMember(
  'Maternal Grandmothers Father',
  true,
  false
);
family.mother.mother.father.mother = new FamilyMember('Older3', false, false);
family.mother.mother.father.father = new FamilyMember('Older4', false, false);
family.mother.father.mother = new FamilyMember(
  'Maternal Grandfathers Mother',
  false,
  false
);
family.mother.father.mother.mother = new FamilyMember('Older5', false, false);
family.mother.father.mother.father = new FamilyMember('Older6', false, false);
family.mother.father.father = new FamilyMember(
  'Maternal Grandfathers Father',
  true,
  false
);
family.mother.father.father.mother = new FamilyMember('Older7', false, false);
family.mother.father.father.father = new FamilyMember('Older8', false, false);
family.father.mother.mother = new FamilyMember(
  'Paternal Grandmothers Mother',
  false,
  false
);
family.father.mother.mother.mother = new FamilyMember('Older9', false, false);
family.father.mother.mother.father = new FamilyMember('Older10', false, false);
family.father.mother.father = new FamilyMember(
  'Paternal Grandmothers Father',
  true,
  false
);
family.father.mother.father.mother = new FamilyMember('Older11', false, false);
family.father.mother.father.father = new FamilyMember('Older12', false, false);
family.father.father.mother = new FamilyMember(
  'Paternal Grandfathers Mother',
  false,
  false
);
family.father.father.mother.mother = new FamilyMember('Older13', false, false);
family.father.father.mother.father = new FamilyMember('Older14', false, false);
family.father.father.father = new FamilyMember(
  'Paternal Grandfathers Father',
  true,
  false
);
family.father.father.father.mother = new FamilyMember('Older15', false, false);
family.father.father.father.father = new FamilyMember('Older16', false, false);

export default family;
