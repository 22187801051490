import { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { FormikValues, FormikHelpers } from 'formik';

import axios, { AxiosError } from 'axios';

import Box from '@material-ui/core/Box';

import FormikStepper from './FormikStepper';
import ContactDetails from './ContactDetails';
import PolishAncestor from './PolishAncestor';
import MinorsCheck from './MinorsCheck';
import MilitaryCheck from './MilitaryCheck';
import EmigrationType from './EmigrationType';
import NaturalizationCheck from './NaturalizationCheck';
import RenunciationCheck from './RenunciationCheck';
import WedlockCheck from './WedlockCheck';
import DocumentsQuery from './DocumentsQuery';
import MartialStatusCheck from './MartialStatusCheck';
import FormikStep from './FormikStep';

import * as yup from 'yup';
import 'yup-phone';
import { useTranslate, useTolgee } from '@tolgee/react';

const SurveyForm = () => {
  const history = useHistory();
  const [errors, setErrors] = useState<AxiosError<any>>();

  const tolgee = useTolgee(['language']);
  const { t } = useTranslate();

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    const olderRegex = /Older*/g;
    let older = false;
    for (const anc of values.ancestor) {
      if (anc.match(olderRegex)) {
        older = true;
      }
    }
    const mainAncestors = values.ancestor.map((name: string) => ({
      name: name,
    }));
    const ancestors = values.ancestors.map((name: string) => ({
      name: name,
    }));
    const soldiers = values.soldiers.map((name: string) => ({
      name: name,
    }));
    const naturalized = values.naturalized.map((name: string) => ({
      name: name,
    }));
    const renunciated = values.renunciated.map((name: string) => ({
      name: name,
    }));
    const born = values.born.map((name: string) => ({
      name: name,
    }));
    const language = tolgee.getLanguage();

    const data = {
      main_ancestors: mainAncestors,
      ancestors: ancestors,
      guest: {
        first_name: values.first_name,
        last_name: values.last_name,
        mail: values.mail,
        phone: values.phone,
        country: values.country,
        city: values.city,
        language: language ? language : 'en',
      },
      minor: values.minor,
      emigration: values.emigration,
      in_army: values.in_army,
      soldiers: soldiers,
      naturalization: values.naturalization,
      naturalized: naturalized,
      renunciation: values.renunciation,
      renunciated: renunciated,
      born_wedlock: values.born_wedlock,
      born: born,
      documents: values.documents,
      martial_status: values.martial_status,
    };

    if (older) {
      delete data.minor;
      delete data.in_army;
      delete data.soldiers;
      delete data.naturalization;
      delete data.naturalized;
      delete data.renunciation;
      delete data.renunciated;
      delete data.born_wedlock;
      delete data.born;
      data.main_ancestors = [{ name: 'Older' }];
      data.ancestors = [{ name: 'Older' }];
    }
    if (data.emigration === 'modern-times') {
      delete data.in_army;
      delete data.soldiers;
      delete data.naturalization;
      delete data.naturalized;
    }
    if (data.in_army !== 'yes') {
      delete data.soldiers;
    }
    if (data.in_army === '') {
      delete data.in_army;
    }
    if (data.naturalization !== 'yes') {
      delete data.naturalized;
    }
    if (data.renunciation !== 'yes') {
      delete data.renunciated;
    }
    if (data.born_wedlock !== 'yes') {
      delete data.born;
    }
    if (data.guest.phone === '+') {
      delete data.guest.phone;
    }
    if (data.guest.country === '') {
      delete data.guest.country;
    }
    if (data.guest.city === '') {
      delete data.guest.city;
    }
    try {
      setErrors(undefined);
      await axios.post(
        'https://survey-backend.yourrootsinpoland.com/api/surveys/',
        //'http://127.0.0.1:8000/api/surveys/',
        data
      );
      history.push('/survey-sent');
    } catch (e: unknown) {
      const error = e as AxiosError;
      setErrors(error);
    }
  };

  const ancestorValidationSchema = yup.object({
    ancestor: yup
      .array()
      .of(yup.string())
      .min(1, t('ancestor_must_be_checked')),
    ancestors: yup.array().of(yup.string()),
  });

  const contactDetailsValidationSchema = yup.object({
    first_name: yup.string().required(t('first_name_is_required')),
    last_name: yup.string().required(t('last_name_is_required')),
    mail: yup.string().email().required(t('email_is_required')),
    phone: yup.string(),
  });

  const documentsValidationSchema = yup.object({
    documents: yup.string().required(t('documents_check_is_required')),
  });

  const emigrationValidationSchema = yup.object({
    emigration: yup.string().required(t('emigration_type_check_is_required')),
  });

  const martialStatusValidationSchema = yup.object({
    martial_status: yup
      .string()
      .required(t('martial_status_check_is_required')),
  });

  const militaryValidationSchema = yup.object({
    in_army: yup.string().required(t('military_check_is_required')),
    soldiers: yup
      .array()
      .of(yup.string())
      .when('in_army', {
        is: 'yes',
        then: yup.array().min(1, t('check_at_least_one_ancestor')),
      }),
  });

  const minorsCheckValidationSchema = yup.object({
    minor: yup.string().required(t('minors_check_is_required')),
  });

  const naturalizationValidationSchema = yup.object({
    naturalization: yup
      .string()
      .required(t('naturalization_check_is_required')),
    naturalized: yup
      .array()
      .of(yup.string())
      .when('naturalization', {
        is: 'yes',
        then: yup.array().min(1, t('check_at_least_one_ancestor')),
      }),
  });

  const renunciationValidationSchema = yup.object({
    renunciation: yup.string().required(t('renunciation_check_is_required')),
    renunciated: yup
      .array()
      .of(yup.string())
      .when('renunciation', {
        is: 'yes',
        then: yup.array().min(1, t('check_at_least_one_ancestor')),
      }),
  });

  const wedlockValidationSchema = yup.object({
    born_wedlock: yup.string().required(t('wedlock_check_is_required')),
    born: yup
      .array()
      .of(yup.string())
      .when('born_wedlock', {
        is: 'yes',
        then: yup.array().min(1, t('check_at_least_one_ancestor')),
      }),
  });

  return (
    <Box marginTop="2rem">
      <FormikStepper
        initialValues={{
          first_name: '',
          last_name: '',
          mail: '',
          phone: '',
          minor: '',
          emigration: '',
          in_army: '',
          soldiers: [],
          naturalization: '',
          naturalized: [],
          renunciation: '',
          renunciated: [],
          born_wedlock: '',
          born: [],
          documents: '',
          martial_status: '',
          ancestor: ['I'],
          ancestors: [],
          country: '',
          city: '',
        }}
        onSubmit={handleSubmit}
      >
        <FormikStep validationSchema={ancestorValidationSchema}>
          <PolishAncestor />
        </FormikStep>
        <FormikStep validationSchema={minorsCheckValidationSchema}>
          <MinorsCheck />
        </FormikStep>
        <FormikStep validationSchema={emigrationValidationSchema}>
          <EmigrationType />
        </FormikStep>
        <FormikStep validationSchema={militaryValidationSchema}>
          <MilitaryCheck />
        </FormikStep>
        <FormikStep validationSchema={naturalizationValidationSchema}>
          <NaturalizationCheck />
        </FormikStep>
        <FormikStep validationSchema={renunciationValidationSchema}>
          <RenunciationCheck />
        </FormikStep>
        <FormikStep validationSchema={wedlockValidationSchema}>
          <WedlockCheck />
        </FormikStep>
        <FormikStep validationSchema={documentsValidationSchema}>
          <DocumentsQuery />
        </FormikStep>
        <FormikStep validationSchema={martialStatusValidationSchema}>
          <MartialStatusCheck />
        </FormikStep>
        <FormikStep validationSchema={contactDetailsValidationSchema}>
          <ContactDetails errors={errors} />
        </FormikStep>
      </FormikStepper>
    </Box>
  );
};

export default SurveyForm;
