import Container from '../components/ui/Container';
import Dashboard from '../components/dashboard/Dashboard';

const DashboardPage = () => {
  return (
    <Container>
      <Dashboard />
    </Container>
  );
};

export default DashboardPage;
