import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Minor from '../../../types/Minor';
import { T, useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: '1rem',
    alignContent: 'center',
  },
  question: {
    fontWeight: 600,
  },
  tooltip: {
    fontSize: '0.9rem',
    marginTop: '1rem',
    fontStyle: 'italic',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
}));

const MinorsCheck = () => {
  const formikContext = useFormikContext<Minor>();
  const { t } = useTranslate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikContext.setFieldValue('minor', event.currentTarget.value);
  };

  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} md={8} justifyContent="center">
        <Box marginTop="2rem" width="100%">
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.question}>
              <T keyName="did_your_polish_ancestor_emigrate_as_a_minor">
                Did your Polish ancestor emigrate as a minor?
              </T>
            </FormLabel>
            <Box component="span" className={classes.tooltip}>
              <T keyName="the_citizenship_of_minors_is_dependent_on_their_parents">
                The citizenship of minors is dependent on their parent(s)
              </T>
            </Box>
            <RadioGroup
              aria-label={t('did_your_polish_ancestor_emigrate_as_a_minor')}
              name="minor"
              onChange={handleChange}
              value={formikContext.values.minor.toString()}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={t('yes')}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={t('no')}
              />
              <FormControlLabel
                value="dunno"
                control={<Radio color="primary" />}
                label={t('i_dont_know')}
              />
            </RadioGroup>
            {!!formikContext.touched.minor && !!formikContext.errors.minor && (
              <p className={classes.errorMessage}>
                {formikContext.errors.minor}
              </p>
            )}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MinorsCheck;
