import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import Container from '../ui/Container';

import { Ancestor } from '../../types/Survey';

interface LoginPageProps {
  emigration?: string;
  documents?: string;
  martial_status?: string;
  created_at?: string;
  main_ancestors?: Ancestor[];
  ancestors?: Ancestor[];
  in_army?: string;
  soldiers?: Ancestor[];
  naturalization?: string;
  naturalized?: Ancestor[];
  renunciation?: string;
  renunciated?: Ancestor[];
  born_wedlock?: string;
  born?: Ancestor[];
}

const SurveyDetails = (props: LoginPageProps) => {
  const uniqueAncestors = (ancestors: string[]) => {
    return ancestors.sort().filter((item, pos, ary) => {
      return !pos || item !== ary[pos - 1];
    });
  };

  const handleAncestors = (
    mainAncestors: Ancestor[] | undefined,
    ancestors: Ancestor[] | undefined
  ): string[] => {
    if (mainAncestors && ancestors) {
      const allAncestors = [...mainAncestors, ...ancestors];
      let allAncestorsStr: string[] = [];
      allAncestors.forEach((ancestor) => {
        allAncestorsStr.push(ancestor.name);
      });
      allAncestorsStr = uniqueAncestors(allAncestorsStr);

      return allAncestorsStr;
    }
    return [];
  };

  const handleDunno = (
    in_army?: string,
    naturalization?: string,
    renunciation?: string,
    born_wedlock?: string
  ): string => {
    let result = '';
    in_army && in_army === 'dunno' ? (result += 'in army, ') : (result += '');
    naturalization && naturalization === 'dunno'
      ? (result += 'naturalization, ')
      : (result += '');
    renunciation && renunciation === 'dunno'
      ? (result += 'renunciation, ')
      : (result += '');
    born_wedlock && born_wedlock === 'dunno'
      ? (result += 'born wedlock, ')
      : (result += '');
    if (result.length > 0) {
      result = result.substring(0, result.length - 2);
    }
    return result;
  };

  return (
    <Container>
      <Grid item xs={12}>
        <Grid container item xs={12} justifyContent="center">
          <Box width="100%">
            <Box component="h3">Survey</Box>
          </Box>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            direction="column"
            style={{ textAlign: 'right', fontWeight: 700 }}
          >
            <Box width="100%">
              <Box component="p">Emigration:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Documents:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Martial status:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Don't know about:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Created at:</Box>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            direction="column"
            style={{ textAlign: 'left', paddingLeft: '1rem' }}
          >
            <Box width="100%">
              <Box component="p">
                {props.emigration?.replaceAll('-', ' ') || 'none'}
              </Box>
            </Box>
            <Box width="100%">
              <Box component="p">
                {props.documents?.replaceAll('-', ' ') || 'none'}
              </Box>
            </Box>
            <Box width="100%">
              <Box component="p">
                {props.martial_status?.replaceAll('-', ' ') || 'none'}
              </Box>
            </Box>
            <Box width="100%">
              <Box component="p">
                {handleDunno(
                  props.in_army,
                  props.naturalization,
                  props.renunciation,
                  props.born_wedlock
                ) || 'none'}
              </Box>
            </Box>
            <Box width="100%">
              <Box component="p">
                {moment(props.created_at).format('LLL') || 'none'}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Box width="100%">
            <Box component="h3">Who emigrated?</Box>
          </Box>
          <Box width="100%">
            {props.main_ancestors &&
              props.main_ancestors.map((ancestor) => (
                <Chip
                  key={ancestor.name}
                  label={ancestor.name}
                  color="primary"
                  style={{ margin: '0.2rem' }}
                />
              ))}
          </Box>
        </Grid>
        {props.ancestors && props.ancestors.length > 0 && (
          <Grid container item xs={12} justifyContent="center">
            <Box width="100%">
              <Box component="h3">Other ancestors</Box>
            </Box>
            <Box width="100%">
              {props.ancestors &&
                props.ancestors.map((ancestor) => (
                  <Chip
                    key={ancestor.name}
                    label={ancestor.name}
                    color="primary"
                    style={{ margin: '0.2rem' }}
                  />
                ))}
            </Box>
          </Grid>
        )}
        {props.in_army === 'yes' && props.soldiers && (
          <Grid container item xs={12} justifyContent="center">
            <Box width="100%">
              <Box component="h3">Military service before 1951</Box>
            </Box>
            <Box width="100%">
              {props.soldiers.map((ancestor) => (
                <Chip
                  label={ancestor.name}
                  color="primary"
                  style={{ margin: '0.2rem' }}
                />
              ))}
            </Box>
          </Grid>
        )}
        {props.naturalization === 'yes' && props.naturalized && (
          <Grid container item xs={12} justifyContent="center">
            <Box width="100%">
              <Box component="h3">Naturalized before 1951</Box>
            </Box>
            <Box width="100%">
              {props.naturalized.map((ancestor) => (
                <Chip
                  label={ancestor.name}
                  color="primary"
                  style={{ margin: '0.2rem' }}
                />
              ))}
            </Box>
          </Grid>
        )}
        {props.renunciation === 'yes' && props.renunciated && (
          <Grid container item xs={12} justifyContent="center">
            <Box width="100%">
              <Box component="h3">Renounced citizenship</Box>
            </Box>
            <Box width="100%">
              {props.renunciated.map((ancestor) => (
                <Chip
                  label={ancestor.name}
                  color="primary"
                  style={{ margin: '0.2rem' }}
                />
              ))}
            </Box>
          </Grid>
        )}
        {props.born_wedlock === 'yes' && props.born && (
          <Grid container item xs={12} justifyContent="center">
            <Box width="100%">
              <Box component="h3">Born out of wedlock</Box>
            </Box>
            <Box width="100%">
              {props.born.map((ancestor) => (
                <Chip
                  label={ancestor.name}
                  color="primary"
                  style={{ margin: '0.2rem' }}
                />
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SurveyDetails;
