import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fecb09',
      light: '#fffcf3',
    },
    secondary: {
      main: '#eb7d46',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#fecb09',
        },
      },
    },
  },
});

export default theme;
