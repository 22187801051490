import Container from '../components/ui/Container';
import Survey from '../components/survey/Survey';

const SurveyPage = () => {
  return (
    <Container>
      <Survey />
    </Container>
  );
};

export default SurveyPage;
