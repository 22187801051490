import React from 'react';
import ReactDOM from 'react-dom';

import '@fontsource/roboto';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  DevTools,
  LanguageDetector,
  Tolgee,
  TolgeeProvider,
} from '@tolgee/react';
import TagManager from 'react-gtm-module';

const tolgee = Tolgee()
  .use(DevTools())
  .use(LanguageDetector())
  .init({
    language: 'en',
    availableLanguages: ['en', 'es'],
    staticData: {
      en: () => import('./i18n/en.json'),
      es: () => import('./i18n/es.json'),
    },
  });

const tagManagerArgs = {
  gtmId: 'AW-463117805',
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <TolgeeProvider tolgee={tolgee} fallback="Loading...">
      <App />
    </TolgeeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
