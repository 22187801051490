import { Field, useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FormGroup } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import family from '../../../helpers/family';
import WedlockAncestor from '../../../types/WedlockAncestor';
import { T, useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: '1rem',
    alignContent: 'center',
  },
  question: {
    fontWeight: 600,
  },
  tooltip: {
    fontSize: '0.9rem',
    marginTop: '1rem',
    fontStyle: 'italic',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
}));

const MilitaryCheck = () => {
  const formikContext = useFormikContext<WedlockAncestor>();
  const { t } = useTranslate();

  const ancestors: string[] = [];
  for (const anc of formikContext.values.ancestor) {
    const newAncestors: string[] = [];
    family.printAncestors(family, anc, newAncestors);
    newAncestors.pop();
    for (let anc of newAncestors) {
      if (!ancestors.includes(anc) && anc !== 'Me') {
        ancestors.push(anc);
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikContext.setFieldValue('born_wedlock', event.currentTarget.value);
  };

  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} md={8} justifyContent="center">
        <Box marginTop="2rem">
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.question}>
              <T keyName="wedlock">
                Was any of your ancestors born out of the wedlock?
              </T>
            </FormLabel>
            <Box component="span" className={classes.tooltip}>
              <T keyName="wedlock_desc">
                Why we ask about it? - It was often required, in male lineages,
                for children to be born in wedlock or acknowledged by the father
                for the citizenship to be inherited.
              </T>
            </Box>
            <RadioGroup
              aria-label={t('wedlock')}
              name="in_army"
              onChange={handleChange}
              value={formikContext.values.born_wedlock.toString()}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={t('yes')}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={t('no')}
              />
              <FormControlLabel
                value="dunno"
                control={<Radio color="primary" />}
                label={t('i_dont_know')}
              />
            </RadioGroup>
            {!!formikContext.touched.born_wedlock &&
              !!formikContext.errors.born_wedlock && (
                <p className={classes.errorMessage}>
                  {formikContext.errors.born_wedlock}
                </p>
              )}
            {formikContext.values.born_wedlock === 'yes' && (
              <FormGroup className={classes.radioGroup}>
                <FormLabel component="legend" className={classes.question}>
                  <T keyName="check_ancestor">Check ancestors(s)</T>:
                </FormLabel>
                {ancestors.map((ancestor) => (
                  <Field
                    type="checkbox"
                    component={CheckboxWithLabel}
                    name="born"
                    key={ancestor}
                    value={ancestor}
                    Label={{
                      label: t(ancestor.toLowerCase().replaceAll(' ', '_')),
                    }}
                  />
                ))}
                {ancestors.length < 1 && (
                  <p>You don't have any ancestors to choose from</p>
                )}
                {formikContext.values.born_wedlock === 'yes' &&
                  !!formikContext.touched.born &&
                  !!formikContext.errors.born && (
                    <p className={classes.errorMessage}>
                      {formikContext.errors.born}
                    </p>
                  )}
              </FormGroup>
            )}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MilitaryCheck;
