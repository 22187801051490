import { useEffect, useState } from 'react';

import { Field, useFormikContext } from 'formik';

import Geocode from 'react-geocode';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MuiPhoneNumber from 'material-ui-phone-number';

import Guest from '../../../types/Guest';
import { AxiosError } from 'axios';

import TextFieldStyled from '../../styles/TextFieldStyled';
import { T, useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: '#f44336',
    marginTop: 0,
    marginLeft: '14px',
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  phone: {
    '& .MuiOutlinedInput-adornedStart .MuiOutlinedInput-notchedOutline': {
      borderWidth: 2,
    },
  },
  question: {
    fontWeight: 600,
  },
  tooltip: {
    fontSize: '0.9rem',
    marginTop: '1rem',
    fontStyle: 'italic',
  },
}));

Geocode.setApiKey('AIzaSyD0skLXxXE_pWI70-wlaoAv2kxj5sND2Z0');

interface ContactDetailsProps {
  errors: AxiosError | undefined;
}

const ContactDetails = (props: ContactDetailsProps) => {
  const formikContext = useFormikContext<Guest>();
  const [countryCode, setCountryCode] = useState<string | undefined>('us');
  const { t } = useTranslate();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      Geocode.fromLatLng(
        String(position.coords.latitude),
        String(position.coords.longitude)
      ).then(
        (response) => {
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case 'locality':
                  formikContext.setFieldValue(
                    'city',
                    response.results[0].address_components[i].long_name
                  );
                  break;
                // case 'administrative_area_level_1':
                //   state = response.results[0].address_components[i].long_name;
                //   break;
                case 'country':
                  setCountryCode(
                    response.results[0].address_components[
                      i
                    ].short_name.toLowerCase()
                  );
                  formikContext.setFieldValue(
                    'country',
                    response.results[0].address_components[i].long_name
                  );
                  break;
              }
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
    });
    return () => {
      setCountryCode(undefined);
    };
  }, []);

  const handlePhoneValue = (event: string) => {
    formikContext.setFieldValue('phone', event);
  };

  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} md={8} justifyContent="center">
        <Box marginTop="2rem">
          <Box className={classes.question}>
            <T keyName="contact_details">Contact details</T>
          </Box>
          <Field
            component={TextFieldStyled}
            variant="outlined"
            required
            fullWidth
            id="first_name"
            name="first_name"
            label={t('first_name')}
          />
          <Field
            component={TextFieldStyled}
            variant="outlined"
            required
            fullWidth
            id="last_name"
            name="last_name"
            label={t('last_name')}
          />
          <Field
            component={TextFieldStyled}
            variant="outlined"
            required
            fullWidth
            id="mail"
            name="mail"
            label={t('email')}
          />
          {props.errors?.response?.data.guest.mail && (
            <p className={classes.errorMessage}>
              Survey with this email address already exists. Check your email
              and make sure to check the spam folder too, in case the email
              won't reach you.
            </p>
          )}
          <Field
            component={MuiPhoneNumber}
            variant="outlined"
            fullWidth
            id="phone"
            name="phone"
            label={t('phone')}
            defaultCountry={countryCode}
            onChange={handlePhoneValue}
            error={
              !!formikContext.touched.phone && !!formikContext.errors.phone
            }
            helperText={
              formikContext.touched.phone && formikContext.errors.phone
            }
            value={formikContext.values.phone}
            className={classes.phone}
          />
          {props.errors?.response?.data.guest.phone && (
            <p className={classes.errorMessage}>
              {props.errors?.response?.data.guest.phone}
            </p>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactDetails;
