import { useState, useEffect, useContext } from 'react';

import moment from 'moment';

import axios from 'axios';

import { Link } from 'react-router-dom';

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridSortModel,
} from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';

import Survey, { Ancestor } from '../../types/Survey';

import AuthContext from '../../store/AuthContext';
interface SurveyRow {
  id?: number;
  date: string;
  clientName?: string;
  mail?: string;
  country?: string;
  emigration?: string;
  ancestors?: string;
  done?: boolean;
  language?: string;
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 1 },
  { field: 'date', headerName: 'Date', flex: 2 },
  {
    field: 'clientName',
    headerName: 'Client',
    flex: 2,
  },
  {
    field: 'mail',
    headerName: 'Mail',
    flex: 2,
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 1,
  },
  {
    field: 'emigration',
    headerName: 'Emigration time',
    flex: 2,
  },
  {
    field: 'ancestors',
    headerName: 'Ancestors',
    flex: 3,
  },
  {
    field: 'done',
    headerName: 'Done',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Chip
          label={params.value ? 'yes' : 'no'}
          color={params.value ? 'primary' : 'secondary'}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
      );
    },
  },
  {
    field: 'language',
    headerName: 'Lang',
    flex: 1,
  },
  {
    field: 'link',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Button
          component={Link}
          to={'/surveys/' + params.row.id}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        >
          details
        </Button>
      );
    },
    renderHeader: (params: GridColumnHeaderParams) => <span></span>,
  },
];

const SurveyTable = () => {
  const [surveys, setSurveys] = useState<SurveyRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(
    Number(localStorage.getItem('pageSize')) || 20
  );
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);

  const authContext = useContext(AuthContext);

  const uniqueAncestors = (ancestors: string[]) => {
    return ancestors.sort().filter((item, pos, ary) => {
      return !pos || item !== ary[pos - 1];
    });
  };

  const handleAncestors = (
    mainAncestors: Ancestor[],
    ancestors: Ancestor[]
  ): string => {
    const allAncestors = [...mainAncestors, ...ancestors];
    let allAncestorsStr: string[] = [];
    allAncestors.forEach((ancestor) => {
      allAncestorsStr.push(ancestor.name);
    });
    allAncestorsStr = uniqueAncestors(allAncestorsStr);

    let returnedAncestors = '';
    allAncestors.forEach((ancestor) => {
      returnedAncestors += ancestor.name + ', ';
    });
    returnedAncestors = returnedAncestors.substring(
      0,
      returnedAncestors.length - 2
    );
    return returnedAncestors;
  };

  const handlePagesSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    localStorage.setItem('pageSize', newPageSize.toString());
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<Survey[]>(
        'https://survey-backend.yourrootsinpoland.com/api/surveys/',
        {
          headers: {
            Authorization: `Token ${authContext.token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.statusText === 'OK') {
          const surveyRows = response.data.map((survey: Survey) => {
            return {
              id: survey.id,
              clientName:
                survey.guest.first_name + ' ' + survey.guest.last_name,
              mail: survey.guest.mail,
              emigration: survey.emigration.replaceAll('-', ' '),
              ancestors: handleAncestors(
                survey.main_ancestors,
                survey.ancestors
              ),
              date: moment(survey.created_at).format('LLL'),
              country: survey.guest.country,
              done: survey.done,
              language: survey.guest.language ? survey.guest.language : 'none',
            };
          });
          setSurveys(surveyRows);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [setIsLoading]);

  return (
    <div style={{ width: '100%' }}>
      {!isLoading && (
        <DataGrid
          rows={surveys}
          columns={columns}
          pageSize={pageSize}
          disableSelectionOnClick
          autoHeight
          onPageSizeChange={handlePagesSizeChange}
          rowsPerPageOptions={[5, 10, 20, 50]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      )}
      {isLoading && <CircularProgress color="secondary" />}
    </div>
  );
};

export default SurveyTable;
