import Bar from './Bar';
import TableContainer from '@material-ui/core/TableContainer';

import SurveyTable from './SurveyTable';

const Dashboard = () => {
  return (
    <>
      <Bar />
      <TableContainer>
        <SurveyTable />
      </TableContainer>
    </>
  );
};

export default Dashboard;
