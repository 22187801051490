import { createContext, useState } from 'react';

interface IResponse {
  token: string;
}

interface IAuthContext {
  token: string | null;
  isLoggedIn: boolean;
  login: (response: IResponse) => void;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext>({
  token: null,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token')
  );

  const isLoggedIn = !!token;

  const loginHandler = (response: IResponse) => {
    setToken(response.token);
    localStorage.setItem('token', response.token);
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const contextValue = {
    token: token,
    isLoggedIn: isLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
