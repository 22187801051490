import { makeStyles } from '@material-ui/core/styles';

import PersonIcon from '@material-ui/icons/Person';
import { useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme) => ({
  ancestor: {
    margin: '1rem 0',
  },
  name: {
    fontSize: '0.6rem',
    margin: 0,
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#fff',
  },
  picture: {
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

interface AncestorProps {
  name: string;
  translate: string;
  setTreeValue: (id: number, name: string, checked: boolean) => void;
  checked: boolean;
  ancestor: boolean;
  id: number;
}

const Ancestor = (props: AncestorProps) => {
  const classes = useStyles();
  const { t } = useTranslate();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    props.setTreeValue(props.id, props.name, props.checked);
  };

  return (
    <div onClick={handleClick} className={classes.ancestor}>
      <PersonIcon
        color={props.checked || props.ancestor ? 'secondary' : 'disabled'}
        className={classes.picture}
        fontSize="large"
        id={props.name}
      />
      <p className={classes.name}>{t(props.translate)}</p>
    </div>
  );
};

export default Ancestor;
