import Container from '../components/ui/Container';
import Login from '../components/login/Login';

const LoginPage = () => {
  return (
    <Container>
      <Login />
    </Container>
  );
};

export default LoginPage;
