import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import WholeForm from '../../../types/WholeForm';
import { T, useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderColor: theme.palette.primary.main,
    fontWeight: 600,
    borderWidth: '2px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  logo: {
    width: '50%',
    height: 'auto',
    maxWidth: '150px',
    display: 'none',
  },
}));

interface INavigationProps {
  step: number;
  handleBackButton: (formValues: WholeForm) => void;
  isLastStep: () => boolean;
}

const Navigation = (props: INavigationProps) => {
  const formikContext = useFormikContext<WholeForm>();
  const { t } = useTranslate();

  const classes = useStyles();

  const justify = props.step === 0 ? 'center' : 'space-around';
  const flexDirection = props.step === 0 ? 'column' : 'row';
  const bottomLogoOrder: number | undefined = props.step === 0 ? 3 : 1;

  const handleClick = () => {
    props.handleBackButton(formikContext.values);
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        direction={flexDirection}
        wrap="nowrap"
        justifyContent={justify}
        alignItems="center"
        xs={12}
        md={8}
      >
        {props.step > 0 && (
          <Grid item>
            <Button
              onClick={handleClick}
              variant="outlined"
              color="default"
              size="large"
              className={classes.button}
            >
              <T keyName="back">Back</T>
            </Button>
          </Grid>
        )}
        <Grid item style={{ order: 2 }}>
          <Button
            type="submit"
            variant="outlined"
            color="default"
            size="large"
            className={classes.button}
          >
            {props.isLastStep() ? t('submit') : t('next')}
          </Button>
        </Grid>
        <Grid item style={{ order: bottomLogoOrder }}>
          <Grid container justifyContent="center">
            <img
              src="/rotsy_Obszar roboczy 1-01.png"
              alt="Your Roots in Poland logo"
              className={classes.logo}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navigation;
