import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';

import theme from './theme/Theme';

import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import SurveyPage from './pages/SurveyPage';
import End from './components/survey/questions/End';
import SurveyDetailsPage from './pages/SurveyDetailsPage';

import { AuthContextProvider } from './store/AuthContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <Router>
          <Switch>
            <Route exact path="/surveys/:id">
              <SurveyDetailsPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/dashboard">
              <DashboardPage />
            </Route>
            <Route path="/survey-sent">
              <End />
            </Route>
            <Route path="/">
              <SurveyPage />
            </Route>
          </Switch>
        </Router>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
