import { Field, useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { FormGroup } from '@material-ui/core';
import MilitaryAncestors from '../../../types/MilitaryAncestors';
import family from '../../../helpers/family';
import { CheckboxWithLabel } from 'formik-material-ui';
import { T, useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: '1rem',
    alignContent: 'center',
  },
  question: {
    fontWeight: 600,
  },
  tooltip: {
    fontSize: '0.9rem',
    marginTop: '1rem',
    fontStyle: 'italic',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
}));

const MilitaryCheck = () => {
  const formikContext = useFormikContext<MilitaryAncestors>();
  const { t } = useTranslate();

  const maleAncestors: string[] = [];
  for (let anc of formikContext.values.ancestor) {
    const newMaleAncestors: string[] = [];
    family.getAncestors(family, anc, newMaleAncestors, true);
    for (let anc of newMaleAncestors) {
      if (!maleAncestors.includes(anc) && anc !== 'Me') {
        maleAncestors.push(anc);
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikContext.setFieldValue('in_army', event.currentTarget.value);
  };

  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} md={8} justifyContent="center">
        <Box marginTop="2rem">
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.question}>
              <T keyName="serve_army">
                Did any of your male ancestors serve in any non-Polish army?
              </T>
            </FormLabel>
            <Box component="span" className={classes.tooltip}>
              <T keyName="serve_army_desc">
                The military service abroad prior to 1951 could cause loss of
                citizenship, please answer carefully.
              </T>
            </Box>
            <RadioGroup
              aria-label={t('serve_army')}
              name="in_army"
              onChange={handleChange}
              value={formikContext.values.in_army.toString()}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={t('yes')}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={t('no')}
              />
              <FormControlLabel
                value="dunno"
                control={<Radio color="primary" />}
                label={t('i_dont_know')}
              />
            </RadioGroup>
            {!!formikContext.touched.in_army &&
              !!formikContext.errors.in_army && (
                <p className={classes.errorMessage}>
                  {formikContext.errors.in_army}
                </p>
              )}
            {formikContext.values.in_army === 'yes' && (
              <FormGroup className={classes.radioGroup}>
                <FormLabel component="legend" className={classes.question}>
                  <T keyName="check_ancestor">Check ancestors(s)</T>:
                </FormLabel>
                {maleAncestors.map((maleAncestor) => (
                  <Field
                    type="checkbox"
                    component={CheckboxWithLabel}
                    name="soldiers"
                    key={maleAncestor}
                    value={maleAncestor}
                    Label={{
                      label: t(maleAncestor.toLowerCase().replaceAll(' ', '_')),
                    }}
                  />
                ))}
                {maleAncestors.length < 1 && (
                  <p>You don't have any ancestors to choose from</p>
                )}
              </FormGroup>
            )}
            {formikContext.values.in_army === 'yes' &&
              !!formikContext.touched.soldiers &&
              !!formikContext.errors.soldiers && (
                <p className={classes.errorMessage}>
                  {formikContext.errors.soldiers}
                </p>
              )}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MilitaryCheck;
