import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import LinearProgressStyled from '../../styles/LinearProgressStyled';

interface IProgressBarProps {
  step: number;
  max: number;
}

const ProgressBar = (props: IProgressBarProps) => {
  const progress = (props.step / props.max) * 100;

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={10} sm={11} md={7}>
        <Box width='100%' mr={1}>
          <LinearProgressStyled variant='determinate' value={progress} />
        </Box>
      </Grid>
      <Grid item xs={2} sm={1} md={1}>
        <Typography variant='body2' color='textPrimary'>{`${Math.round(
          progress
        )}%`}</Typography>
      </Grid>
    </Grid>
    // <Box display='flex' alignItems='center' marginTop='2rem'>
    //   <Box width='100%' mr={1} >

    //   </Box>

    // </Box>
  );
};

export default ProgressBar;
