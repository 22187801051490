import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const LinearProgressStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '1rem',
      borderRadius: 84,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    colorPrimary: {
      backgroundColor: theme.palette.common.white,
    },
  })
)(LinearProgress);

export default LinearProgressStyled;
