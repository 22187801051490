import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { T } from '@tolgee/react';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '2rem',
  },
  logo: {
    width: '100%',
    height: 'auto',
    marginBottom: '2rem',
    maxWidth: '400px',
  },
}));

const End = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container justifyContent="center">
        <img
          src="/rotsy_Obszar roboczy 1-02.png"
          alt="Your Roots in Poland logo"
          className={classes.logo}
        />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={8}>
          <Typography
            variant="h3"
            align="center"
            color="primary"
            gutterBottom
            className={classes.header}
          >
            <T keyName="thank_you_for_completing_the_survey">
              Thank you for completing the survey!
            </T>
          </Typography>
        </Grid>
        <Grid item xs={10} md={8}>
          <Typography variant="body1" align="justify" gutterBottom>
            <T keyName="thanks_desc">
              We will analyze your answers and get back to you at the latest
              within two business days. Please make sure to check the spam
              folder too, in case the email won't reach you.
            </T>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default End;
