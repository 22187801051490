import { useState, useEffect, useContext } from 'react';

import axios from 'axios';

import Bar from '../dashboard/Bar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';

import ClientDetails from './ClientDetails';
import SurveyDetails from './SurveyDetails';

import Survey from '../../types/Survey';
import AuthContext from '../../store/AuthContext';

interface SurveyDetailProps {
  id: string;
}

const SurveyDetail = (props: SurveyDetailProps) => {
  const [survey, setSurvey] = useState<Survey>();
  const [done, setDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<Survey>(
        'https://survey-backend.yourrootsinpoland.com/api/surveys/' + props.id,
        {
          headers: {
            Authorization: `Token ${authContext.token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setSurvey(response.data);
        setDone(response.data.done);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDone(event.target.checked);
    axios.patch<Survey>(
      'https://survey-backend.yourrootsinpoland.com/api/surveys/' +
        props.id +
        '/',
      { done: event.target.checked },
      {
        headers: {
          Authorization: `Token ${authContext.token}`,
        },
      }
    );
  };

  return (
    <>
      <Bar />
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          {!isLoading && (
            <>
              <ClientDetails
                id={survey?.guest.id}
                first_name={survey?.guest.first_name}
                last_name={survey?.guest.last_name}
                mail={survey?.guest.mail}
                phone={survey?.guest.phone}
                city={survey?.guest.city}
                country={survey?.guest.country}
                language={survey?.guest.language}
              />
            </>
          )}
          {isLoading && <CircularProgress color="secondary" />}
        </Grid>
        <Grid item xs={6}>
          {!isLoading && (
            <>
              <SurveyDetails
                emigration={survey?.emigration}
                documents={survey?.documents}
                martial_status={survey?.martial_status}
                created_at={survey?.created_at}
                main_ancestors={survey?.main_ancestors}
                ancestors={survey?.ancestors}
                in_army={survey?.in_army}
                soldiers={survey?.soldiers}
                naturalization={survey?.naturalization}
                naturalized={survey?.naturalized}
                renunciation={survey?.renunciation}
                renunciated={survey?.renunciated}
                born_wedlock={survey?.born_wedlock}
                born={survey?.born}
              />
            </>
          )}
          {isLoading && <CircularProgress color="secondary" />}
        </Grid>
        <Grid item xs={12}>
          {!isLoading && (
            <FormControlLabel
              control={
                <Switch
                  checked={done}
                  onChange={handleChange}
                  name="done"
                  color="primary"
                />
              }
              label="Done"
            />
          )}
          {isLoading && <CircularProgress color="secondary" />}
        </Grid>
        <Grid item xs={12}>
          <Button
            component={Link}
            to={'/dashboard'}
            variant="contained"
            color="primary"
            style={{ marginTop: '1rem' }}
          >
            back
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SurveyDetail;
