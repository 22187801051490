import React, { useState } from 'react';

import {
  Formik,
  Form,
  FormikConfig,
  FormikValues,
  FormikHelpers,
} from 'formik';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Navigation from '../navigation/Navigation';

import ProgressBar from '../progressBar/ProgressBar';

import { FormikStepProps } from './FormikStep';
import WholeForm from '../../../types/WholeForm';
import family from '../../../helpers/family';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        margin: `${theme.spacing(1)}px 0`,
      },
    },
  })
);

const FormikStepper = ({ children, ...props }: FormikConfig<FormikValues>) => {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[
    step
  ] as React.ReactElement<FormikStepProps>;

  const classes = useStyles();

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  const handleBackButton = (formValues: WholeForm) => {
    const maleAncestors: string[] = [];
    for (let anc of formValues.ancestor) {
      const newMaleAncestors: string[] = [];
      family.getAncestors(family, anc, newMaleAncestors, true);
      for (let anc of newMaleAncestors) {
        if (!maleAncestors.includes(anc) && anc !== 'Me') {
          maleAncestors.push(anc);
        }
      }
    }

    const olderRegex = /Older*/g;
    let older = false;
    for (const anc of formValues.ancestor) {
      if (anc.match(olderRegex)) {
        older = true;
      }
    }

    switch (step) {
      case 2:
        if (older) {
          setStep((previousStep) => previousStep - 2);
        } else {
          setStep((previousStep) => previousStep - 1);
        }
        break;
      case 4:
        if (
          formValues.emigration === 'modern-times' ||
          maleAncestors.length <= 0
        ) {
          setStep((previousStep) => previousStep - 2);
        } else {
          setStep((previousStep) => previousStep - 1);
        }
        break;
      case 5:
        if (formValues.emigration === 'modern-times') {
          setStep((previousStep) => previousStep - 3);
        } else {
          setStep((previousStep) => previousStep - 1);
        }
        break;
      case 7:
        if (older) {
          setStep((previousStep) => previousStep - 5);
        } else {
          setStep((previousStep) => previousStep - 1);
        }
        break;
      default:
        setStep((previousStep) => previousStep - 1);
        break;
    }
  };

  const handleSubmit = async (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (isLastStep()) {
      await props.onSubmit(values, helpers);
    } else {
      const maleAncestors: string[] = [];
      for (let anc of values.ancestor) {
        const newMaleAncestors: string[] = [];
        family.getAncestors(family, anc, newMaleAncestors, true);
        for (let anc of newMaleAncestors) {
          if (!maleAncestors.includes(anc) && anc !== 'Me') {
            maleAncestors.push(anc);
          }
        }
      }

      const olderRegex = /Older*/g;
      let older = false;
      for (const anc of values.ancestor) {
        if (anc.match(olderRegex)) {
          older = true;
        }
      }

      switch (step) {
        case 0:
          if (older) {
            setStep((previousStep) => previousStep + 2);
          } else {
            setStep((previousStep) => previousStep + 1);
          }
          break;
        case 2:
          if (older) {
            setStep((previousStep) => previousStep + 5);
          } else if (
            values.emigration.toString() !== 'modern-times' &&
            maleAncestors.length > 0
          ) {
            setStep((previousStep) => previousStep + 1);
          } else if (values.emigration.toString() !== 'modern-times') {
            setStep((previousStep) => previousStep + 2);
          } else {
            setStep((previousStep) => previousStep + 3);
          }
          break;
        default:
          setStep((previousStep) => previousStep + 1);
          break;
      }
      helpers.setTouched({});
    }
  };

  return (
    <>
      <ProgressBar step={step + 1} max={childrenArray.length} />
      <Formik
        {...props}
        onSubmit={handleSubmit}
        validationSchema={currentChild.props.validationSchema}
      >
        <Form autoComplete="off" className={classes.root}>
          {currentChild}
          <Navigation
            step={step}
            handleBackButton={handleBackButton}
            isLastStep={isLastStep}
          />
        </Form>
      </Formik>
    </>
  );
};

export default FormikStepper;
