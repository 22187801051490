import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Container from '../ui/Container';

interface LoginPageProps {
  city?: string;
  country?: string;
  first_name?: string;
  id?: number;
  last_name?: string;
  mail?: string;
  phone?: string;
  language?: string;
}

const ClientDetails = (props: LoginPageProps) => {
  return (
    <Container>
      <Grid item xs={12}>
        <Grid container item xs={12} justifyContent="center">
          <Box width="100%">
            <Box component="h3">Client</Box>
          </Box>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            direction="column"
            style={{ textAlign: 'right', fontWeight: 700 }}
          >
            <Box width="100%">
              <Box component="p">ID:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">First name:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Last name:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Mail:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Phone:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">City:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Country:</Box>
            </Box>
            <Box width="100%">
              <Box component="p">Language:</Box>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            direction="column"
            style={{ textAlign: 'left', paddingLeft: '1rem' }}
          >
            <Box width="100%">
              <Box component="p">{props.id}</Box>
            </Box>
            <Box width="100%">
              <Box component="p">{props.first_name || 'none'}</Box>
            </Box>
            <Box width="100%">
              <Box component="p">{props.last_name || 'none'}</Box>
            </Box>
            <Box width="100%">
              <Box component="p">{props.mail || 'none'}</Box>
            </Box>
            <Box width="100%">
              <Box component="p">{props.phone || 'none'}</Box>
            </Box>
            <Box width="100%">
              <Box component="p">{props.city || 'none'}</Box>
            </Box>
            <Box width="100%">
              <Box component="p">{props.country || 'none'}</Box>
            </Box>
            <Box width="100%">
              <Box component="p">{props.language || 'none'}</Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientDetails;
