class FamilyMember {
  name: string;
  male: boolean;
  child: FamilyMember | null;
  mother: FamilyMember | null;
  father: FamilyMember | null;
  checked: boolean;

  constructor(name: string, male: boolean, checked: boolean) {
    this.name = name;
    this.male = male;
    this.mother = null;
    this.father = null;
    this.child = null;
    this.checked = checked;
  }

  findFamilyMember(
    node: FamilyMember | null,
    name: string
  ): FamilyMember | undefined {
    if (node === null) {
      return;
    }

    if (node.name === name) {
      return node;
    }

    const motherResult = this.findFamilyMember(node.mother, name);
    if (motherResult) {
      return motherResult;
    }

    const fatherResult = this.findFamilyMember(node.father, name);
    return fatherResult;
  }

  printAncestors(node: FamilyMember | null, name: string, array: string[]) {
    if (node === null) {
      return false;
    }

    if (node.name === name) {
      array.push(node.name);
      return true;
    }

    if (
      this.printAncestors(node.mother, name, array) ||
      this.printAncestors(node.father, name, array)
    ) {
      array.push(node.name);
      return true;
    }

    return false;
  }

  getAncestors(
    node: FamilyMember | null,
    name: string,
    array: string[],
    male: boolean
  ) {
    if (node === null) {
      return false;
    }

    if (node.name === name) {
      if (node.male === male) {
        array.push(node.name);
      }
      return true;
    }

    if (
      this.getAncestors(node.mother, name, array, male) ||
      this.getAncestors(node.father, name, array, male)
    ) {
      if (node.male === male) {
        array.push(node.name);
      }
      return true;
    }

    return false;
  }
}

export default FamilyMember;
