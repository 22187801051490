import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Documents from '../../../types/Documents';
import { T, useTranslate } from '@tolgee/react';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: '1rem',
    alignContent: 'center',
  },
  question: {
    fontWeight: 600,
  },
  tooltip: {
    fontSize: '0.9rem',
    marginTop: '1rem',
    fontStyle: 'italic',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    textAlign: 'center',
  },
}));

const DocumentsQuery = () => {
  const formikContext = useFormikContext<Documents>();
  const { t } = useTranslate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikContext.setFieldValue('documents', event.currentTarget.value);
  };

  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12} md={8} justifyContent="center">
        <Box marginTop="2rem">
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.question}>
              <T keyName="documents">
                Do you have documents concerning your ancestors?
              </T>
            </FormLabel>
            <Box component="span" className={classes.tooltip}>
              <T keyName="documents_desc">
                Don't worry, if you do not have any documents or the ones you
                have would not suffice. We can find those for you!
              </T>
            </Box>
            <RadioGroup
              aria-label={t('documents')}
              name="documents"
              onChange={handleChange}
              value={formikContext.values.documents.toString()}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value="polish"
                control={<Radio color="primary" />}
                label={t('i_have_polish_original_documents')}
              />
              <FormControlLabel
                value="non-polish"
                control={<Radio color="primary" />}
                label={t('i_have_non_polish_original_documents')}
              />
              <FormControlLabel
                value="digital"
                control={<Radio color="primary" />}
                label={t('i_have_copies_and_or_digital_documents')}
              />
              <FormControlLabel
                value="none"
                control={<Radio color="primary" />}
                label={t('i_do_not_have_any_documents')}
              />
            </RadioGroup>
            {!!formikContext.touched.documents &&
              !!formikContext.errors.documents && (
                <p className={classes.errorMessage}>
                  {formikContext.errors.documents}
                </p>
              )}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DocumentsQuery;
