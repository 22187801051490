import { useParams } from 'react-router';

import Container from '../components/ui/Container';
import SurveyDetail from '../components/dashboard/SurveyDetail';

interface Params {
  id: string;
}

const SurveyPage = () => {
  const { id } = useParams<Params>();

  return (
    <Container>
      <SurveyDetail id={id} />
    </Container>
  );
};

export default SurveyPage;
