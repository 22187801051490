import { useContext, useState } from 'react';

import { Field, Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';

import axios from 'axios';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import TextFieldStyled from '../styles/TextFieldStyled';

import AuthContext from '../../store/AuthContext';

const useStyles = makeStyles((theme) => ({
  field: {
    margin: '1rem 0',
  },
  button: {
    borderColor: theme.palette.primary.main,
    fontWeight: 600,
    borderWidth: '2px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  errorMessage: { color: 'red' },
}));

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const Login = () => {
  const [displayError, setDisplayError] = useState(false);
  const [formError, setFormError] = useState('');

  const history = useHistory();

  const authContext = useContext(AuthContext);

  const classes = useStyles();

  const handleSubmit = async (values: FormikValues) => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        authContext.login({ token: token });
        history.push('/dashboard');
      } else {
        const response = await axios.post(
          'https://survey-backend.yourrootsinpoland.com/api-token-auth/',
          values
        );
        if (response.statusText === 'OK') {
          setDisplayError(false);
          authContext.login(response.data);
          history.push('/dashboard');
        }
      }
    } catch (e) {
      setFormError('Unable to log in with provided credentials.');
      setDisplayError(true);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        xs={12}
        md={6}
        justifyContent="center"
        direction="column"
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <Field
              component={TextFieldStyled}
              variant="outlined"
              required
              fullWidth
              id="username"
              name="username"
              label="Username"
              className={classes.field}
            />
            <Field
              component={TextFieldStyled}
              variant="outlined"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              className={classes.field}
              type="password"
            />
            {displayError && (
              <div className="formStatus">
                <p className={classes.errorMessage}>{formError}</p>
              </div>
            )}
            <Button
              type="submit"
              variant="outlined"
              color="default"
              size="large"
              className={classes.button}
            >
              Sign In
            </Button>
          </Form>
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Login;
