import { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import AuthContext from '../../store/AuthContext';

const Bar = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = () => {
    authContext.logout();
    history.push('/login');
  };

  return (
    <AppBar position="static">
      <Toolbar style={{ justifyContent: 'flex-end' }}>
        <Button onClick={handleLogout}>Logout</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Bar;
