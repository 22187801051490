import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';

const TextFieldStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '& input:invalid + fieldset': {
        borderWidth: 2,
      },
      '& input:valid + fieldset': {
        borderWidth: 2,
      },
    },
  })
)(TextField);

export default TextFieldStyled;
